.nav-main{
   background:#e8efec;
    padding-top:rem-calc(20);
    padding-bottom:rem-calc(20);
}

.nav__group{
    background-color:#f6f8f0;
    margin-bottom:rem-calc(10);
    &.active{
        > a{
            background:$lightgreen;
        }
    }
    > a{
        display:block;
        border-top:1px solid #5a6b68;
        border-bottom:1px solid #6b7d7a;
        background:linear-gradient(to bottom, #657774, #a2b7b3);
        color:white !important;
        font-weight:bold;
        text-decoration:none;
        padding:3px 5px 3px 10px;
        font-size:rem-calc(14);
    }
    li{
        border-bottom:1px dotted #666;
        padding-top:3px;
        padding-bottom:3px;
        padding-left:rem-calc(15);

        &.active{
            a{
                color:$lightgreen;
                &:hover{
                    text-decoration:underline;
                }
            }
        }
        a{
            color:$blue;
            text-decoration:none;
            transition:all 0.2s linear 0s;
            font-size:rem-calc(14);
            &:hover{
                color:$green;

            }

        }

    }
}
