body{
   // @include responsiveborder;
    font-size:rem-calc(14);
}

.btn-continue{
    background-image:linear-gradient(to bottom, #4f5856,#6f7b78);
    border:1px solid $darkgreen;
    border-radius:6px;
    color:white !important;
    padding:5px;
    line-height:1.5em;
    display:inline-flex;
    align-items:stretch;
    &:after{
        content:"\00BB";
        margin:-5px -5px -5px 5px;
        color:#333333;
        background:#9fc001;
        display:inline-block;
        padding:4px 8px;
        font-size:1.5em;
        font-weight:bold;
        border-radius:0 6px 6px 0;
        transition:all 0.2s linear 0s;
    }
    &:hover{
        &:after{
            background:darken(#9fc001, 2%);
        }

    }
}

.btn-continue-sm{
    text-transform:uppercase;
    font-size:rem-calc(12);
    padding:2px 5px;
    &:after{
        font-size:1.25em;
        padding:2px 5px;
        margin:-2px -5px -2px 5px;
    }
}



.alert-success{
    background-color:$successbgcolor;
    border-color:$successbordercolor;
    color:$darkgreen;
}

.table-striped{
    margin-top:rem-calc(5);
    @extend .table-hover;
    border:1px solid $green;
    border-collapse: collapse;
    tfoot{
        border-top:4px double $green;
    }
    thead{
        th {
            background-color:$green3;
            color:white;
        }
    }

    td, & th{
        border:none;
        font-size:rem-calc(12);
    }

}

.form-control, .form-select, .btn{
    font-size:rem-calc(14);
}
