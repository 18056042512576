main{
    .content{
        background:white;
        padding:rem-calc(20) rem-calc(40);

        strong, b{
            color:$darkgreen;
        }

    }


}

