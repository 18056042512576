body{
    background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGUlEQVQYV2NcuXnDf30tbQbGG3dv/2cAAgBL9Achqr84rAAAAABJRU5ErkJggg==') repeat;
   // margin-top:rem-calc(5);

}


.btn-primary{
    background-image:linear-gradient(to bottom, #4f5856,#6f7b78);
    background-color:#6f7b78;
    border:1px solid $darkgreen;
    border-radius:3px;
    color:white !important;
    transition:all 0.2s linear 0s;
    &:hover{
        color:white;
        background-image:none;
        background-color:#4f5856;
    }
}

a{
    transition:all 0.2s linear 0s;
}



h1, .h1{
    margin-top:1em;
    margin-bottom:2em;
}

hr{
    margin-top:3em;
    margin-bottom:3em;
    .card & {
        margin-top:1em;
        margin-bottom:1em;
    }
}

h2, .h2{
    margin-top:2em;
    font-weight:bold;
    margin-bottom:1em;
}



.card{
    .card-header{
        font-weight:bold;
    }
    form{
        .col-form-label{
            @extend .text-end;
            //font-size:0.92rem;
            padding-right:rem-calc(15) !important;
        }
        .row{
            > * {
                padding-left:1px;
                padding-right:1px;
            }
        }
    }
}


.form-select-sm, .form-control-sm{
    padding-top:5px;
    padding-bottom:5px;
}


