$body-bg: #d8dddb;
$font-size-base:              1rem !default;


$headings-color: #88a800;

$line-height-base:1.4;
//$spacer: 1.4rem;

//$grid-gutter-width:0.5rem;

$card-cap-bg: $green3;
$card-cap-color:  white;
$card-bg: $grey;
$card-border-color: $darkgrey;

$link-color: $blue;
$link-decoration:none;
$link-hover-color:$lightgreen;
$link-hover-decoration:underline;

$table-cell-padding-y:        5px;
$table-cell-padding-x:        5px;
$table-cell-vertical-align:   middle;

$table-color:$darkgreen;

$input-bg: #fff;


