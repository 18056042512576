$green: #96A7A2;
$lightgreen:#88A800;
$darkgreen: #4a5956;
$hover: #8faa1f;
$border-green: #788783;
$blue: #4d6e9a;
$black:#152626;
$footer: #b5c3bf;
$footerlink: #c1df28;
$grey:#E8EFEC;
$darkgrey:#aec4c0;

$grey2:#eaefec;
$green3: #879a97;
$hovercolor:#d7ecaa;
$successbordercolor:#d8ed8b;
$successbgcolor:#f7ffd6;
$linkgreen:#C5D870;
$hovergreencolor:#b3d527;
$hovermenucolor: #939f9c;

$color2: rgb(157, 168 ,166);

$theme-colors: (
    "lightgreen":$linkgreen,
    "darkgreen":#677B72,
    "blue":$blue,
    "error": #e91111,
    "grey" : $grey,
    "hover": $hover,
    "success" : $green,
    "darkgrey":$darkgrey
);
$font-size-base: 1rem;
$link-color:$blue;
$link-hover-color:$linkgreen;
//$body-color:$darkgreen;

//$input-btn-padding-y-sm:      1px;

$font-family-base: Verdana, Arial, Helvetica, sans-serif;
$table-hover-bg:           $hovercolor;


$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1150px
);

$h1-font-size:                $font-size-base * 1.2;
$h2-font-size:                $font-size-base * 1;
$h3-font-size:                $font-size-base * 1.05;
$h4-font-size:                $font-size-base * 1.025;
$h5-font-size:                $font-size-base * 1.01;
$h6-font-size:                $font-size-base * 0.75;


$accordion-bg:white;
$accordion-color:black;
$accordion-button-active-bg: white;

