.infobox{
    background-color:$grey2;
    border:1px solid $footer;
    padding:rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(70);
    position:relative;
    margin:rem-calc(30) 0;
    min-height:95px;
    &:before{
        content:url(../img/issp/warndreieck.png);
        position:absolute;
        top:30px;
        left:10px;
    }
    > :last-child{
      margin-bottom:0;
    }
    ul{
        li::marker{
            color:$green;
        }
    }
    strong{
        color:$darkgreen;
    }

}

