.footer{
    background:$darkgreen;
    padding-top:5px;
    padding-bottom:5px;
    color:white;
    border-radius:0 0 10px 10px;
    font-size:rem-calc(11);
    li{
        border-left:1px solid white;
        padding-left:10px;
        &:first-of-type{
            padding-left:0;
            border-left:none;
        }
    }
    a{

        color:$footerlink;
        &:hover{
            color:$lightgreen;
        }
    }

}
