.header{
    background:linear-gradient(to bottom, #748a87 0%, #a1b6b1 30%, #889e9b 66%, #748a87 95%, #424e4c 100%);
    a{
        color:white;
        transition:all 0.2s linear 0s;
        &:hover{
            color:darken(white, 20%);
        }
    }
    ul{
        margin-bottom:5px;
    }
    li{
        border-left:1px solid white;
        padding-left:10px;
        font-size:rem-calc(12);
        &:first-of-type{
            border-left:none;
            padding-left:0;
        }
    }
}

.header__topbar{
    color:white;
    border-bottom:1px solid $darkgreen;
    font-size:rem-calc(12);
    .col-auto{
        border-left:1px solid white;
        padding-top:5px;
        padding-bottom:5px;
    }
}
