@function rem-calc($size) {
  @return #{$size / 16}rem;
}

@function em-calc($s1, $s2){
  @return #{$s2/$s1}em;
}

@mixin responsiveborder{


  border-top:3px solid black;
  &:before{
    position:fixed;
    top:0;
    left:0;
    content:"XS";
    background:black;
    color:white;
    z-index:100000;
  }
  &.admin-bar{
    &:before{
      top:35px;
      @include media-breakpoint-down(sm){
        top:48px;
      }
    }

  }
  @include media-breakpoint-only(sm){
    border-top-color:$blue;
    &:before{
      content:"SM";
      background:$blue;
    }
  }
  @include media-breakpoint-only(md){
    border-top-color:$orange;
    &:before{
      background:$orange;
      content:"MD";
    }
  }
  @include media-breakpoint-only(lg){
    border-top-color:$green;
    &:before{
      background:$green;
      content:"LG";
    }
  }
  @include media-breakpoint-only(xl){
    border-top-color:$yellow;
    &:before{
      background:$yellow;
      content:"XL";
    }
  }
    @include media-breakpoint-only(xxl){
        border-top-color:white;
        &:before{
            background:white;
            color:black;
            content:"XXL";
        }
    }
}
